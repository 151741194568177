import { forkJoin } from 'rxjs';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPagesService } from '../shared/pages.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsAppsService } from '../../apps/shared/apps.service';
import { cloneDeep, filter } from 'lodash';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { validateJson } from '../../shared/json-editor/json-validator.directive';
import { NameValidationPattern } from '../../shared/common.service';
var CmsEditPageTemplateComponent = /** @class */ (function () {
    function CmsEditPageTemplateComponent(fb, activeRoute, router, pageService, translateService, appService, notifyService, notificationsService) {
        this.fb = fb;
        this.activeRoute = activeRoute;
        this.router = router;
        this.pageService = pageService;
        this.translateService = translateService;
        this.appService = appService;
        this.notifyService = notifyService;
        this.notificationsService = notificationsService;
        this.classes = 'popup-overlay';
        this.layoutsData = [];
        this.appTemplates = [];
        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };
        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
    }
    CmsEditPageTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pageTemplate = this.fb.group({
            name: ['', [
                    Validators.required,
                    Validators.maxLength(40),
                    Validators.pattern(NameValidationPattern.pattern)
                ]
            ],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            subtitle: ['', [Validators.maxLength(120)]],
            appTemplate: ['', [Validators.required]],
            keywords: ['', [Validators.maxLength(120)]],
            customData: [{}, [validateJson]],
            active: [true, []]
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.pageService.getPageTemplate(params['id']),
                _this.pageService.getLayouts(),
                _this.appService.getAppsTemplates({ pageSize: 100000 })
            ]).subscribe(function (_a) {
                var pageTemplateData = _a[0], layoutsData = _a[1], appsTemplatesData = _a[2];
                _this.layoutsData = layoutsData.layouts;
                _this.pageTemplate.addControl('layout', _this.buildLayouts(pageTemplateData.pageTemplate.layouts));
                _this.appTemplates = appsTemplatesData.appTemplates;
                _this.pageTemplate.patchValue(pageTemplateData.pageTemplate);
            });
        });
    };
    CmsEditPageTemplateComponent.prototype.onSubmit = function (form) {
        var _this = this;
        var updatedPageTemplate = cloneDeep(form.value);
        updatedPageTemplate.appTemplate = form.value.appTemplate.id || form.value.appTemplate;
        updatedPageTemplate.layouts = form.value.layout.map(function (val, index) {
            return {
                id: _this.layoutsData[index].id,
                selected: val
            };
        }).filter(function (val) {
            return val.selected;
        });
        delete updatedPageTemplate.layout;
        this.pageService.updatePageTemplate(updatedPageTemplate, this.activeRoute.snapshot.params['id']).subscribe(function (response) {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('pages_template_edited')
            });
            _this.notifyService.notify({
                type: 'page-templates',
                data: response.pageTemplate
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsEditPageTemplateComponent.prototype.buildLayouts = function (activeLayouts) {
        var _this = this;
        var arr = this.layoutsData.map(function (layout) {
            var found = filter(activeLayouts, function (el) {
                return el.id === layout.id;
            });
            return _this.fb.control(found.length);
        });
        return this.fb.array(arr);
    };
    Object.defineProperty(CmsEditPageTemplateComponent.prototype, "layouts", {
        get: function () {
            return this.pageTemplate.get('layout');
        },
        enumerable: true,
        configurable: true
    });
    ;
    CmsEditPageTemplateComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    };
    return CmsEditPageTemplateComponent;
}());
export { CmsEditPageTemplateComponent };

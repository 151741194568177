<div class="popup g-popup"
     (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">{{'pages_new' | translate}}</div>
  </header>
  <div class="g-popup-body">
    <form class="popup-form"
          novalidate
          [formGroup]="form"
          (ngSubmit)="onSubmit(form)">
          <div class="form-body">
            <div class="g-row">
              <div class="g-col-sm-12">
                <div class="form-control">
                  <cms-select formControlName="pageTemplate"
                              [options]="pageTemplates"
                              [title]="'pages_templates' | translate"
                              [placeholder]="'pages_templates' | translate"
                              (selectChanged)="selectPageTemplate($event)"
                  ></cms-select>
                </div>
                <div class="form-control">
                  <div class="g-input g-input-io"
                       [ngClass]="{invalid: (form.get('name').hasError('required')
                                  || form.get('name').hasError('maxlength'))
                                  && !form.get('name').pristine}">
                    <input type="text" class="g-input-input"
                           [placeholder]="'common_name' | translate"
                           formControlName="name">
                    <label class="g-input-label">
                      {{ 'common_name' | translate }}
                    </label>
                    <label class="g-input-validation">
                        <span *ngIf="form.get('name').hasError('required')">
                            {{ 'common_validation_field_required' | translate }}
                        </span>
                      <span *ngIf="form.get('name').hasError('maxlength')">
                        {{'common_validation_maximum_field_length' | translate:nameValidationInterpolationParams}}
                      </span>
                      <span *ngIf="form.get('name').hasError('pattern')">
                              {{'common_validation_valid_characters' | translate:nameValidationInterpolationParams}}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="form-control">
                  <div class="g-input g-input-io"
                       [ngClass]="{invalid: (form.get('title').hasError('required')
                       || form.get('title').hasError('maxlength'))
                       && !form.get('title').pristine}">
                    <input type="text"
                           class="g-input-input"
                           [placeholder]="'common_title' | translate"
                           formControlName="title">
                    <label class="g-input-label">
                      {{ 'common_title' | translate }}
                    </label>
                    <label class="g-input-validation">
                        <span *ngIf="form.get('title').hasError('required')">
                            {{ 'common_validation_field_required' | translate }}
                        </span>
                      <span *ngIf="form.get('title').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:titleValidationInterpolationParams}}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="form-control">
                  <div class="g-input g-input-io"
                       [ngClass]="{invalid: (form.get('subtitle').hasError('required')
                       || form.get('subtitle').hasError('maxlength'))
                       && !form.get('subtitle').pristine}">
                    <input type="text"
                           class="g-input-input"
                           [placeholder]="'common_subtitle' | translate"
                           formControlName="subtitle">
                    <label class="g-input-label">
                      {{ 'common_subtitle' | translate }}
                    </label>
                    <label class="g-input-validation">
                      <span *ngIf="form.get('subtitle').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:subtitleValidationInterpolationParams}}
                      </span>
                    </label>
                    <span class="form-control-reset"
                          [title]="'pages_reset_to_linked' | translate"
                          *ngIf="!form.get('subtitle').pristine
                                  && selectedPageTemplate
                                  && selectedPageTemplate.subtitle !== form.get('subtitle').value"
                          (click)="form.get('subtitle').reset(selectedPageTemplate.subtitle)">
                      <i class="g-icon g-icon-reset-a "></i>
                    </span>
                  </div>
                </div>
                <div class="form-control">
                  <div class="g-input g-input-io"
                       [ngClass]="{invalid: form.get('keywords').hasError('maxlength')
                       && !form.get('keywords').pristine}">
                    <input type="text"
                           class="g-input-input"
                           [placeholder]="'common_keywords' | translate"
                           formControlName="keywords">
                    <label class="g-input-label">
                      {{ 'common_keywords' | translate }}
                    </label>
                    <label class="g-input-validation">
                      <span *ngIf="form.get('keywords').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:keywordsValidationInterpolationParams}}
                      </span>
                    </label>
                    <span class="form-control-reset"
                          [title]="'pages_reset_to_linked' | translate"
                          *ngIf="!form.get('keywords').pristine
                                  && selectedPageTemplate
                                  && selectedPageTemplate.keywords !== form.get('keywords').value"
                          (click)="form.get('keywords').reset(selectedPageTemplate.keywords)">
                      <i class="g-icon g-icon-reset-a "></i>
                    </span>
                  </div>
                </div>
                <div class="form-control">
                  <div class="g-input g-input-io"
                       [ngClass]="{filled: form.get('customData').value,
                                     invalid: form.get('customData').hasError('jsonParseError')
                                    && !form.get('customData').pristine}">
                    <cms-json-editor
                            formControlName="customData">
                    </cms-json-editor>
                    <label class="g-input-validation"
                           *ngIf="form.get('customData').hasError('jsonParseError')">
                      {{'common_validation_parse_error' | translate}}
                    </label>
                    <span class="form-control-reset is-top"
                          [title]="'pages_reset_to_linked' | translate"
                          *ngIf="!form.get('customData').pristine
                                  && selectedPageTemplate
                                  && customDataFieldChanged"
                          (click)="form.get('customData').reset(selectedPageTemplate.customData)">
                      <i class="g-icon g-icon-reset-a "></i>
                    </span>
                  </div>
                </div>
                <div class="form-control">
                  <cms-select formControlName="app"
                              [options]="apps"
                              [title]="'applications_application' | translate"
                              [placeholder]="'applications_application' | translate"
                  ></cms-select>
                </div>
                <div class="form-control form-control-layouts"
                     *ngIf="layouts">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: form.get('layout').value,
                                      invalid: form.get('layout').hasError('checkBoxRequired')
                                      && !form.get('layout').pristine}">
                    <label class="g-input-label">
                      {{'pages_layout' | translate}}
                    </label>
                    <label class="mr-1"
                           [attr.for]="layoutsData[i].id"
                           *ngFor="let layout of layouts.controls; let i=index">
                      <input type="checkbox"
                             [attr.id]="layoutsData[i].id"
                             [formControl]="layout"/>
                      <span class="custom-control-indicator cms-page-layout-{{layoutsData[i].name}}"
                            [ngClass]="{'checked': layout.value}">
                      </span>
                    </label>
                    <label class="g-input-validation">
                        <span *ngIf="form.get('layout').hasError('checkBoxRequired')
                        && !form.get('layout').pristine">
                            {{ 'common_validation_field_required' | translate }}
                        </span>
                    </label>
                      <span class="form-control-reset is-top"
                            [title]="'pages_reset_to_linked' | translate"
                            *ngIf="!form.get('layout').pristine
                                  && selectedPageTemplate
                                  && layoutFieldChanged"
                            (click)="form.get('layout').reset(getPageTemplateSelectedLayouts(selectedPageTemplate.layouts))">
                      <i class="g-icon g-icon-reset-a "></i>
                    </span>
                  </div>
                </div>
                <div class="form-control">
                  <div class="g-toggle g-toggle-io">
                    <input class="g-toggle-input"
                           type="checkbox"
                           formControlName="active">
                    <label class="g-toggle-label">
                      {{ 'common_active' | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-footer">
            <div class="form-footer-buttons">
              <button type="submit"
                      class="form-footer-button g-button g-button-earth"
                      [disabled]="form.invalid">
                <span class="g-button-title">
                  {{'common_save' | translate}}
                </span>
              </button>
            </div>
          </div>
      </form>
  </div>
</div>
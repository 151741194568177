import {Component, HostBinding} from '@angular/core';
import { CmsApplicationSettingsService, SETTINGS_TYPES } from '../application-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsAppsService, App } from '../../apps/shared/apps.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { KeyValidationPattern } from '../../shared/common.service';
import { sortBy } from 'lodash';
import {validateJson} from '../../shared/json-editor/json-validator.directive';

@Component({
    selector: 'cms-application-settings-new',
    templateUrl: 'application-settings-new.component.html',
    providers: [CmsApplicationSettingsService]
})
export class CmsApplicationSettingsNewComponent {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    settingTypes = SETTINGS_TYPES;
    apps: Array<App>;
    keyValidationInterpolationParams;
    private valueValidators = [Validators.required];

    constructor(
        private router: Router,
        private appSettingsService: CmsApplicationSettingsService,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private appService: CmsAppsService,
        private translateService: CmsTranslateService
    ) {
        this.form = this.fb.group({
            appId: ['', [Validators.required]],
            key: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(KeyValidationPattern.pattern)]],
            value: ['', this.valueValidators],
            type: ['', [Validators.required]]
        });

        this.keyValidationInterpolationParams = {
            field: this.translateService.translate('common_key').toLowerCase(),
            maximum: '80',
            characters: KeyValidationPattern.description
        };

        this.appService.getAllApps().subscribe(
            (data) => {
                if (data.apps && data.apps.length) {
                    this.apps = sortBy(data.apps, 'title');
                }
            }
        );
    }

    onSelectChanged(value: any) {
        if (value) {
            if (value.id === 'object' || value.id === 'config') {
                this.form.controls['value'].setValidators(Validators.compose([...this.valueValidators, validateJson]));
            } else {
                this.form.controls['value'].setValidators(this.valueValidators);
            }
        }

        this.form.controls['value'].markAsTouched();
        this.form.controls['value'].markAsDirty({onlySelf: true});
        this.form.controls['value'].updateValueAndValidity();

    }

    onSubmit(form: FormGroup) {
        this.appSettingsService.createAppSetting({
            appId: form.value.appId.id,
            key: form.value.key,
            value: form.value.value,
            type: form.value.type.id,
            search: {
                extend: 'app'
            }
        }).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_create_setting_success')
                });
                this.closePopup().then(() => {
                    this.notifyService.notify('applicationSettingNew');
                });
            },
            error => {
                this.notificationsService.showError(error);
            }
        );
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'preserve'}
        );
    }
}

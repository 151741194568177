<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
  <header class="g-popup-header">
    <i (click)="closePopup()"
       class="g-popup-header-close g-icon g-icon-close-a"></i>
    <div class="g-popup-title">
      {{('pages_template_translate_to' |  translate) + ' ' + localeName}}
    </div>
  </header>
  <div class="g-popup-body">
    <form class="popup-form"
          novalidate
          [formGroup]="form"
          (ngSubmit)="onSubmit(form)">
      <div class="form-body">
        <div class="g-row">
          <div class="g-col-sm-12">
            <div class="form-control page-template-translate-title">
              <div class="g-input g-input-io"
                   [ngClass]="{invalid: (form.get('title').hasError('required')
                       || form.get('title').hasError('maxlength'))
                       && !form.get('title').pristine}">
                <input type="text"
                       class="g-input-input"
                       [placeholder]="'common_title' | translate"
                       formControlName="title"
                       (focus)="activateField('title')">
                <label class="g-input-label">
                  {{ 'common_title' | translate }}
                </label>
                <label class="g-input-validation">
                        <span *ngIf="form.get('title').hasError('required')">
                            {{ 'common_validation_field_required' | translate }}
                        </span>
                  <span *ngIf="form.get('title').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:titleValidationInterpolationParams}}
                      </span>
                </label>
                <div class="cms-input-info-open"
                     *ngIf="activeField['title']"
                     [ngClass]="{'active': baseTranslationDisplay['title']}"
                     (click)="toggleBaseTranslation('title')">
                  <i class="g-icon g-icon-globe"></i>
                  <i class="g-icon g-icon-arrow-down-d"></i>
                </div>
                <div class="cms-input-info-content"
                     *ngIf="baseTranslationDisplay['title']"
                     [attachOutsideOnClick]="baseTranslationDisplay['title']"
                     (cmsClickOutside)="closeBaseTranslation('title')"
                     [exclude]="'.page-template-translate-title'">
                  <span>{{ basePage.title || 'None'  }}</span>
                </div>
              </div>
            </div>
            <div class="form-control page-template-translate-subtitle">
              <div class="g-input g-input-io"
                   [ngClass]="{invalid: (form.get('subtitle').hasError('required')
                       || form.get('subtitle').hasError('maxlength'))
                       && !form.get('subtitle').pristine}">
                <input type="text"
                       class="g-input-input"
                       [placeholder]="'common_subtitle' | translate"
                       formControlName="subtitle"
                       (focus)="activateField('subtitle')">
                <label class="g-input-label">
                  {{ 'common_subtitle' | translate }}
                </label>
                <label class="g-input-validation">
                      <span *ngIf="form.get('subtitle').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:subtitleValidationInterpolationParams}}
                      </span>
                </label>
                <div class="cms-input-info-open"
                     *ngIf="activeField['subtitle']"
                     [ngClass]="{'active': baseTranslationDisplay['subtitle']}"
                     (click)="toggleBaseTranslation('subtitle')">
                  <i class="g-icon g-icon-globe"></i>
                  <i class="g-icon g-icon-arrow-down-d"></i>
                </div>
                <div class="cms-input-info-content"
                     *ngIf="baseTranslationDisplay['subtitle']"
                     [attachOutsideOnClick]="baseTranslationDisplay['subtitle']"
                     (cmsClickOutside)="closeBaseTranslation('subtitle')"
                     [exclude]="'.page-template-translate-subtitle'">
                  <span>{{ basePage.subtitle || 'None'  }}</span>
                </div>
              </div>
            </div>
            <div class="form-control page-template-translate-keywords">
              <div class="g-input g-input-io"
                   [ngClass]="{invalid: form.get('keywords').hasError('maxlength')
                       && !form.get('keywords').pristine}">
                <input type="text"
                       class="g-input-input"
                       [placeholder]="'common_keywords' | translate"
                       formControlName="keywords"
                       (focus)="activateField('keywords')">
                <label class="g-input-label">
                  {{ 'common_keywords' | translate }}
                </label>
                <label class="g-input-validation">
                      <span *ngIf="form.get('keywords').hasError('maxlength')">
                          {{'common_validation_maximum_field_length' | translate:keywordsValidationInterpolationParams}}
                      </span>
                </label>
                <div class="cms-input-info-open"
                     *ngIf="activeField['keywords']"
                     [ngClass]="{'active': baseTranslationDisplay['keywords']}"
                     (click)="toggleBaseTranslation('keywords')">
                  <i class="g-icon g-icon-globe"></i>
                  <i class="g-icon g-icon-arrow-down-d"></i>
                </div>
                <div class="cms-input-info-content"
                     *ngIf="baseTranslationDisplay['keywords']"
                     [attachOutsideOnClick]="baseTranslationDisplay['keywords']"
                     (cmsClickOutside)="closeBaseTranslation('keywords')"
                     [exclude]="'.page-template-translate-keywords'">
                  <span>{{ basePage.keywords || 'None'  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="form-footer-buttons">
          <button type="submit"
                  class="form-footer-button g-button g-button-earth"
                  [disabled]="form.invalid">
                <span class="g-button-title">
                  {{'common_save' | translate}}
                </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="popup g-popup" (cmsEscapeKey)="closePopup()">
    <header class="g-popup-header">
        <i (click)="closePopup()" class="g-popup-header-close g-icon g-icon-close-a"></i>
        <div class="g-popup-title">
            {{ 'settings_edit_user_setting' | translate }}
        </div>
    </header>
    <div class="g-popup-body">
        <form class="popup-form" novalidate [formGroup]="userSetting" (ngSubmit)="onSubmit()">
            <div class="form-body">
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: userSetting.get('company').hasError('required')}">
                        <input type="text" class="g-input-input"
                               [placeholder]="'common_company' | translate"
                               formControlName="company">
                        <label class="g-input-label">{{ 'common_company' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: userSetting.get('user').hasError('required')}">
                        <input type="text" class="g-input-input"
                               [placeholder]="'common_user' | translate"
                               formControlName="user">
                        <label class="g-input-label">{{ 'common_user' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{invalid: userSetting.get('key').hasError('required')}">
                        <input type="text" class="g-input-input"
                               [placeholder]="'common_key' | translate"
                               formControlName="key">
                        <label class="g-input-label">{{ 'common_key' | translate }}</label>
                    </div>
                </div>
                <div class="form-control">
                    <cms-select formControlName="type"
                                [options]="settingTypes"
                                [title]="'common_type' | translate"
                                [placeholder]="'common_type' | translate"
                                (selectChanged)="onSelectChanged($event)">
                    </cms-select>
                </div>
                <div class="form-control">
                    <div class="g-input g-input-io"
                         [ngClass]="{filled: userSetting.get('value').value,
                                    invalid: userSetting.get('value').invalid && !userSetting.get('value').pristine}">
                        <ng-container *ngIf="userSetting.value.type.id !== 'object' &&
                                             userSetting.value.type.id !== 'config'">
                            <input type="text"
                                   class="g-input-input"
                                   [placeholder]="'common_value' | translate"
                                   formControlName="value">
                            <label class="g-input-label">{{'common_value' | translate}}</label>
                            <label class="g-input-validation">{{ 'common_validation_field_required' | translate }}</label>
                        </ng-container>
                        <cms-json-editor *ngIf="userSetting.value.type.id === 'object' ||
                                             userSetting.value.type.id === 'config'"
                                     formControlName="value">
                        </cms-json-editor>
                        <label class="g-input-validation"
                               *ngIf="userSetting.get('value').hasError('jsonParseError')">
                            {{'common_validation_parse_error' | translate}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <div class="form-footer-buttons">
                    <button type="submit"
                            class="form-footer-button g-button g-button-earth"
                            [disabled]="userSetting.invalid || !settingChanged">
                        <span class="g-button-title">{{ 'common_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

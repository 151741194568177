
import {map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SITE_CONFIG} from '../../core/site-config.service';
import {ISiteConfig} from '../../core/site-config.interface';
import {Observable} from 'rxjs';
import {Response} from '@angular/http';
import {CmsObjectHelper} from '../../shared/object-helper.service';
import {Pagination} from '../../../types';

export interface CmsPage {
    id: number;
    name: string;
    title: string;
    keywords: string;
    description: string;
    customData: Object;
    linkedPageTemplate?: CmsPageTemplate;
    pageTemplateLayouts: boolean;
    app: number;
    layouts: Array<{ id: number, name: string, title: string, icon: string }>;
    locale?: number;
}

export interface CmsPageTemplate {
    id: number;
    name: string;
    title: string;
    subtitle: string;
    keywords: string;
    description: string;
    customData: Object;
    app: string;
    layouts: Array<{ id: number, name: string, title: string, icon: string }>;
    locale?: number;
}

export interface CmsLayout {
    id: number;
    name: string;
    title: string;
    icon: string;
}

export interface CmsWidget {
  id: number;
  name: string;
  title: string;
  definition?: object;
  active: 1 | 0 | boolean;
  priority: number;
  createdAt: string;
  updatedAt: string;
}

export interface CmsWidgets {
    widgets: Array<CmsWidget>;
}

// list of properties that can be override by parent template
export const FIELDS_OVERRIDE_WHITELIST = [
    {controlKey: 'subtitle'},
    {controlKey: 'keywords'},
    {controlKey: 'customData'},
    {controlKey: 'layout', serverKey: 'layouts'}
];

export const MODULE_ID = 'pages';

@Injectable()
export class CmsPagesService {

    constructor(private http: HttpClient,
                @Inject(SITE_CONFIG) private config: ISiteConfig) {
    }

    _toJson = (res: Response) => {
        const data = res.json();
        return data || {};
    }

    getPages(params?: Object): Observable<{pages: Array<CmsPage>, pagination: Pagination}> {
        return this.http.get<{pages: Array<CmsPage>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/pages`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    createPage(newPage: CmsPage): Observable<any> {
        return this.http.post(`${this.config.gravityCmsApi}/cms/pages`,
            newPage
        );
    }

    getPage(id: string, params?: Object): Observable<{pages: CmsPage}> {
        return this.http.get<{pages: CmsPage}>(
            `${this.config.gravityCmsApi}/cms/pages/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    updatePage(updatedPage: CmsPage, id: string | number): Observable<{pages: CmsPage}> {
        return this.http.put<{pages: CmsPage}>(`${this.config.gravityCmsApi}/cms/pages/${id}`,
            updatedPage
        );
    }

    updatePageTranslation(updatedPage: CmsPage): Observable<{pages: CmsPage}> {
        return this.http.put<{pages: CmsPage}>(`${this.config.gravityCmsApi}/cms/pages/${updatedPage.id}/locales/${updatedPage.locale}`,
            updatedPage
        );
    }

    getPageLayoutWidgets(pageId, layoutId): Observable<any> {
        return this.http.get(`${this.config.gravityCmsApi}/cms/pages/${pageId}/layouts/${layoutId}/widgets`);
    }

    savePageLayoutWidgets(pageId, layoutId, data): Observable<any> {
        return this.http.post(
            `${this.config.gravityCmsApi}/cms/pages/${pageId}/layouts/${layoutId}/widgets`,
            data
        );
    }

    getWidgets(): Observable<CmsWidgets> {
      return this.http.get<CmsWidgets>(`${this.config.gravityCmsApi}/cms/widgets`);
    }

    /**
     *
     * @param newPageTemplate
     * @return {Observable<R>}
     */
    createPageTemplate(newPageTemplate: CmsPageTemplate): Observable<{pageTemplate: CmsPageTemplate}> {
        return this.http.post<{pageTemplate: CmsPageTemplate}>(`${this.config.gravityCmsApi}/cms/page-templates`,
            newPageTemplate
        );
    }

    updatePageTemplate(updatedPageTemplate: CmsPageTemplate, id: string | number): Observable<{pageTemplate: CmsPageTemplate}> {
        return this.http.put<{pageTemplate: CmsPageTemplate}>(`${this.config.gravityCmsApi}/cms/page-templates/${id}`,
            updatedPageTemplate
        );
    }

    getPageTemplate(id: number | string, params?: Object): Observable<{pageTemplate: CmsPageTemplate}> {
        return this.http.get<{pageTemplate: CmsPageTemplate}>(
            `${this.config.gravityCmsApi}/cms/page-templates/${id}`,
            {params: CmsObjectHelper.objToSearchParams(params)}
        );
    }

    updatePageTemplateTranslation(updatedPageTemplate: CmsPageTemplate): Observable<{pageTemplate: CmsPageTemplate}> {
        return this.http.put<{pageTemplate: CmsPageTemplate}>(`${this.config.gravityCmsApi}/cms/page-templates/${updatedPageTemplate.id}
        /locales/${updatedPageTemplate.locale}`,
            updatedPageTemplate
        );
    }

    /**
     *
     * @param params
     * @param {number} params.appTemplate - Filter by base apps
     * @param {string} params.extend - Extend data with related resource information (appTemplate)
     * @return {Observable<R>}
     */
    getPageTemplates(params?: Object): Observable<{pageTemplates: Array<CmsPageTemplate>, pagination: Pagination}> {
        return this.http.get<{pageTemplates: Array<CmsPageTemplate>, pagination: Pagination}>
        (`${this.config.gravityCmsApi}/cms/page-templates`, {
            params: CmsObjectHelper.objToSearchParams(params)
        });
    }

    getLayouts(): Observable<{layouts: Array<CmsLayout>, pagination: Pagination}> {
        return this.http.get<{layouts: Array<CmsLayout>, pagination: Pagination}>(`${this.config.gravityCmsApi}/cms/pages/layouts`);
    }
}

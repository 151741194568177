
import {debounceTime} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {cloneDeep, isEqual} from 'lodash';
import {CmsModalService} from '../../core/modal.service';
import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsNotifyService} from '../../core/notify.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsUserSettingsService, SETTING_TYPES} from '../user-settings.service';

@Component({
    selector: 'cms-user-settings-edit',
    templateUrl: 'user-settings-edit.component.html',
    providers: [CmsUserSettingsService]
})

export class CmsUserSettingsEditComponent implements OnInit {
    userSetting: FormGroup;
    settingTypes = SETTING_TYPES;
    settingChanged = false;
    originalData: any;
    data: any;
    private valueValidators = [Validators.required];

    constructor(private fb: FormBuilder,
                private settingsService: CmsUserSettingsService,
                private modalService: CmsModalService,
                private notifyService: CmsNotifyService,
                private notificationsService: CmsNotificationsService,
                private translateService: CmsTranslateService) {
    }

    ngOnInit() {
        this.userSetting = this.fb.group({
            company: [{
                value: '',
                disabled: true
            }, []],
            user: [{
                value: '',
                disabled: true
            }, []],
            key: [{
                value: '',
                disabled: true
            }, []],
            type: [{id: ''}, [Validators.required]],
            value: ['', this.valueValidators]
        });

        this.setValueValidators(this.data['type']);

        this.userSetting.patchValue({
            company: this.data['company']['title'],
            user: this.data['user'],
            type: {
                id: this.data['type']
            },
            key: this.data['key'],
            value: this.data['value']
        });

        this.originalData = cloneDeep(this.data);

        this.userSetting.valueChanges.pipe(
            debounceTime(200))
            .subscribe((setting: any) => {
                this.settingChanged = !(isEqual(setting.type.id, this.originalData.type) &&
                                        isEqual(setting.value, this.originalData.value));
            });
    }

    onSelectChanged(value: any) {
        this.setValueValidators(value.id);

        this.userSetting.controls['value'].markAsTouched();
        this.userSetting.controls['value'].markAsDirty({onlySelf: true});
        this.userSetting.controls['value'].updateValueAndValidity();
    }

    setValueValidators(type) {
        if (type === 'object' || type === 'config') {
            this.userSetting.controls['value'].setValidators(Validators.compose([...this.valueValidators, validateJson]));
        } else {
            this.userSetting.controls['value'].setValidators(this.valueValidators);
        }
    }

    onSubmit() {
        this.settingsService.createOrUpdateSetting({
            companyId: this.data.company.id,
            user: this.data.user,
            key: this.data.key,
            value: this.userSetting.value.value,
            type: this.userSetting.value.type.id
        }).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('settings_update_setting_success')
                });

                this.notifyService.notify('userSettingEdit');
                this.closePopup();
            },
            error => {
                this.notificationsService.showError(error);
            }
        );
    }

    closePopup() {
        this.modalService.close('user-settings-edit');
    }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CmsNotifyService } from '../../core/notify.service';
import { CmsResources } from '../shared/resources.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import {CmsTranslateService} from '../../shared/translate.service';

@Component({
    selector: 'cms-edit-container',
    templateUrl: 'edit-container.component.html'
})

export class CmsEditContainerComponent implements OnInit {
    public selectedContainer: Object;
    public editContainer: FormGroup;
    titleValidationInterpolationParams;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private notifyService: CmsNotifyService,
        private resourcesService: CmsResources,
        private notificationsService: CmsNotificationsService,
        private fb: FormBuilder,
        private translateService: CmsTranslateService
    ) {
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
    }

    ngOnInit() {
        this.editContainer = this.fb.group({
            name: '',
            title: ['', [Validators.required, Validators.maxLength(80)]],
            active: false,
            createOptimizedImages: false,
        });

        this.activeRoute.params.subscribe((params: {id: string}) => {
            this.resourcesService.getContainer(params.id).subscribe(
                (response) => {
                    this.selectedContainer = response.container;
                    this.editContainer.patchValue({
                        name: response.container.name,
                        title: response.container.title,
                        active: response.container.active,
                        createOptimizedImages: response.container.createOptimizedImages,
                    });
                },
                (err) => {
                    this.notificationsService.showError(err);
                }
            );
        });
    }

    public onSubmit(value) {
        const data = {
            title : this.editContainer.get('title').value,
            active : this.editContainer.get('active').value,
            createOptimizedImages : this.editContainer.get('createOptimizedImages').value,
            id : this.selectedContainer['id']
        };
        this.resourcesService.updateContainer(data).subscribe(
            () => {
                this.notifyService.notify({type: 'containers'});
                this.closePopup();
            },
            (err) => {
                this.notificationsService.showError(err);
            }
        );
    }

    public closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }
}


import {forkJoin} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Component, HostBinding} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {CategoriesResponse, Category, CategoryResponse, CmsArticlesService} from '../shared/articles.service';
import {CmsCommonService, Locale, LocalesResponse, NameValidationPattern} from '../../shared/common.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from 'app/core/notifications.service';
import {clone, isEqual, sortBy} from 'lodash';
import {UserService} from '../../core/user.service';


@Component({
    selector: 'cms-edit-category',
    templateUrl: 'edit-category.component.html'
})

export class CmsEditCategoryComponent {
    @HostBinding('class') classes = 'popup-overlay';
    canEditGlobalCategories: boolean;
    editCategory: FormGroup;
    locales: Array<Object>;
    categories: Array<Object> = [];
    fieldsChanged = false;
    initialValues;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;

    constructor(private activeRoute: ActivatedRoute,
                private articlesService: CmsArticlesService,
                private commonService: CmsCommonService,
                private fb: FormBuilder,
                private notificationsService: CmsNotificationsService,
                private notifyService: CmsNotifyService,
                private router: Router,
                private translateService: CmsTranslateService,
                private userService: UserService,
        ) {

        this.canEditGlobalCategories = userService.data.modules['articles']['specialPermissions'].canEditGlobalArticles;

        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.editCategory = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(80)]],
            active: false,
            locale: [
                {
                    value: '',
                    disabled: true
                },
                [Validators.required]
            ],
            parent: '',
            company: '',
            app: ''
        });

        this.activeRoute.params.subscribe((params: { id: string, localeId: string }) => {
            forkJoin([
                this.commonService.getLocales(),
                this.articlesService.getArticleCategory(params.id, {locale: params.localeId}),
            ]).subscribe(([
                              localeData,
                              categoryData
                          ]: [
                LocalesResponse,
                CategoryResponse
                ]) => {
                this.articlesService
                    .getArticleCategories({company: categoryData.category.company, app: categoryData.category.app, pageSize: 1000})
                    .subscribe((categoriesData: CategoriesResponse) => {
                        this.categories = sortBy(categoriesData.categories.filter((category: Category) => {
                            // Leave all categories except the one we're editing
                            return category.id !== Number(params.id);
                        }), 'title');

                        const selectedParentCategory = this.categories.find((category: Category) => {
                            return category.id === categoryData.category.parent;
                        });

                        this.editCategory.patchValue({
                            parent: selectedParentCategory
                        });

                        this.initialValues = clone(this.editCategory.value);
                    });

                this.locales = localeData.locales;

                const selectedLocale: any = this.locales.find((locale: Locale) => {
                    return locale.id === categoryData.category.locale;
                });

                this.editCategory.patchValue({
                    name: categoryData.category.name,
                    title: categoryData.category.title,
                    active: categoryData.category.active,
                    locale: selectedLocale,
                    company: categoryData.category.company,
                    app: categoryData.category.app
                });

                this.initialValues = clone(this.editCategory.value);
            });
        });

        this.editCategory.valueChanges.pipe(
            debounceTime(200))
            .subscribe((category: any) => {
                this.fieldsChanged = !isEqual(category, this.initialValues);
            });
    }

    onSubmit() {
        // getRawValue is used when there are disabled fields in form
        this.articlesService.editArticleCategory(this.editCategory.getRawValue(), this.activeRoute.snapshot.params['id'])
            .subscribe(() => {
            this.notificationsService.show({
                type: 'action',
                content: 'Category edited'
            });
            this.notifyService.notify('category');
            this.closePopup();
        }, (error) => {
            this.notificationsService.showError(error);
        });
    }

    closePopup() {
        this.router.navigate([{outlets: {popup: null}}], {queryParamsHandling: 'preserve'});
    }
}

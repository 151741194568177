
import {forkJoin} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsPagesService, CmsPageTemplate, CmsPage} from '../shared/pages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsCommonService} from '../../shared/common.service';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsNotifyService} from '../../core/notify.service';
import {CmsNotificationsService} from '../../core/notifications.service';
import {find, cloneDeep} from 'lodash';

@Component({
    selector: 'cms-translate-page',
    templateUrl: './translate-page.component.html',
    providers: [CmsPagesService]
})
export class CmsTranslatePageComponent implements OnInit {

    basePage: CmsPage;
    form: FormGroup;
    titleValidationInterpolationParams;
    subtitleValidationInterpolationParams;
    keywordsValidationInterpolationParams;
    localeName: string;
    activeField: Object = {};
    baseTranslationDisplay: Object = {};

    constructor(private fb: FormBuilder,
                private activeRoute: ActivatedRoute,
                private router: Router,
                private commonService: CmsCommonService,
                private pageService: CmsPagesService,
                private translateService: CmsTranslateService,
                private notifyService: CmsNotifyService,
                private notificationsService: CmsNotificationsService) {
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };

        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };

        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
    }

    ngOnInit() {

        this.form = this.fb.group({
            title: ['', [Validators.required, Validators.maxLength(80)]],
            subtitle: ['', [Validators.maxLength(120)]],
            keywords: ['', [Validators.maxLength(120)]]
        });

        this.activeRoute.params.subscribe((params: { pageId: string, toLocaleId: string }) => {
            forkJoin([
                this.commonService.getLocales(),
                this.pageService.getPage(params.pageId)
            ]).subscribe(([localeData, basePage]) => {
                this.basePage = basePage.pages;
                this.localeName = find(localeData.locales, {'id': Number(params.toLocaleId)}).title;
                this.pageService.getPage(params.pageId, {locale: params.toLocaleId})
                    .subscribe(
                        (res) => {
                            this.form.patchValue(res.pages);
                        }
                    );
            });
        });
    }

    onSubmit(form: FormGroup) {
        const updatedPageTemplate = cloneDeep(form.value);
        updatedPageTemplate.id = this.activeRoute.snapshot.params.pageId;
        updatedPageTemplate.locale = this.activeRoute.snapshot.params.toLocaleId;

        this.pageService.updatePageTranslation(updatedPageTemplate)
            .subscribe(
                (response) => {
                    this.notificationsService.show({
                        type: 'action',
                        content: this.translateService.translate('pages_page_translated')
                    });
                    this.closePopup();
                },
                (error) => {
                    this.notificationsService.showError(error);
                }
            );
    }

    toggleBaseTranslation(item: string) {
        this.baseTranslationDisplay[item] = !this.baseTranslationDisplay[item];
    }

    closeBaseTranslation(item: string) {
        this.baseTranslationDisplay[item] = false;
    }

    activateField(item: string) {
        this.activeField = {};
        this.activeField[item] = true;
    }

    closePopup() {
        this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'merge'}
        );
    }

}

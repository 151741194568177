import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CmsTranslateService} from '../../shared/translate.service';
import {CmsPagesService, CmsLayout} from 'app/pages/shared/pages.service';
import {AppTemplate, CmsAppsService} from '../../apps/shared/apps.service';
import {cloneDeep} from 'lodash';
import {CmsNotificationsService} from '../../core/notifications.service';
import {CmsNotifyService} from '../../core/notify.service';
import {validateJson} from '../../shared/json-editor/json-validator.directive';
import {NameValidationPattern} from '../../shared/common.service';

@Component({
  selector: 'cms-new-page-template',
  templateUrl: './new-page-template.component.html',
  providers: [CmsPagesService]
})
export class CmsNewPageTemplateComponent implements OnInit {
    @HostBinding('class') classes = 'popup-overlay';
  appTemplates: Array<AppTemplate> = [];
  layoutsData: Array<CmsLayout> = [];
  form: FormGroup;
  nameValidationInterpolationParams;
  titleValidationInterpolationParams;
  subtitleValidationInterpolationParams;
  keywordsValidationInterpolationParams;

  constructor(
      private router: Router,
      private fb: FormBuilder,
      private translateService: CmsTranslateService,
      private pageService: CmsPagesService,
      private appService: CmsAppsService,
      private notifyService: CmsNotifyService,
      private notificationsService: CmsNotificationsService
  ) {

    this.nameValidationInterpolationParams = {
      field: this.translateService.translate('common_name').toLowerCase(),
      maximum: '40',
        characters: NameValidationPattern.description
    };

    this.titleValidationInterpolationParams = {
      field: this.translateService.translate('common_title').toLowerCase(),
      maximum: '80'
    };

    this.subtitleValidationInterpolationParams = {
      field: this.translateService.translate('common_subtitle').toLowerCase(),
      maximum: '120'
    };

    this.keywordsValidationInterpolationParams = {
      field: this.translateService.translate('common_keywords').toLowerCase(),
      maximum: '120'
    };

    this.appService.getAppsTemplates({pageSize: 100000}).subscribe(
        (data) => {
          if (data.appTemplates && data.appTemplates.length) {
            this.appTemplates = data.appTemplates;
          }
        }
    );
  }

  ngOnInit() {

    this.form = this.fb.group({
      name: ['', [
          Validators.required,
          Validators.maxLength(40),
          Validators.pattern(NameValidationPattern.pattern)
        ]
      ],
      title: ['', [Validators.required, Validators.maxLength(80)]],
      subtitle: ['', [Validators.maxLength(120)]],
      appTemplate: ['', [Validators.required]],
      keywords: ['', [Validators.maxLength(120)]],
      customData: [{}, [validateJson]],
      active: [true, []]
    });

    this.pageService.getLayouts().subscribe(
        (data) => {
          this.layoutsData = data.layouts;
          this.form.addControl('layout', this.buildLayouts());
        }
    );
  }

  buildLayouts() {
    const arr = this.layoutsData.map(layout => {
      return this.fb.control(false);
    });

    return this.fb.array(arr);
  }

  get layouts(): FormArray {
    return this.form.get('layout') as FormArray;
  };

  onSubmit(form: FormGroup) {
    const newPage = cloneDeep(form.value);

    newPage.appTemplate = form.value.appTemplate.id;
    newPage.layouts = form.value.layout.map((val, index) => {
      return {
        id: this.layoutsData[index].id,
        selected: val
      };
    }).filter((val) => {
      return val.selected;
    });

    this.pageService.createPageTemplate(newPage).subscribe(
        (res) => {
          this.notificationsService.show({
            type: 'action',
            content: this.translateService.translate('pages_template_created')
          });
          this.notifyService.notify({
            type: 'page-templates',
            data: res.pageTemplate
          });
          this.closePopup();
        },
        error => {
          this.notificationsService.showError(error);
        }
    );
  }

  closePopup() {
    this.router.navigate(
        [{outlets: {popup: null}}],
        {queryParamsHandling: 'merge'}
    );
  }

}

import { Component, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsFormsService } from '../forms.service';
import { CmsAppTemplatesService, AppTemplate } from '../../app-templates/shared/app-templates.service';
import { CmsTranslateService } from '../../shared/translate.service';
import { NameValidationPattern } from '../../shared/common.service';
import { sortBy } from 'lodash';
import { validateJson } from '../../shared/json-editor/json-validator.directive';

@Component({
    selector: 'cms-new-form-template',
    templateUrl: 'new-form-template.component.html',
    providers: [CmsFormsService]
})

export class CmsNewFormTemplateComponent {
    @HostBinding('class') classes = 'popup-overlay';
    form: FormGroup;
    appTemplates: Array<AppTemplate>;
    nameValidationInterpolationParams;
    titleValidationInterpolationParams;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private appTemplateService: CmsAppTemplatesService,
        private translateService: CmsTranslateService,
        private formService: CmsFormsService
    ) {
        this.form = this.fb.group({
            appTemplate: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.maxLength(40), Validators.pattern(NameValidationPattern.pattern)]],
            title: ['', [Validators.required, Validators.maxLength(120)]],
            data: [{}, [Validators.required, validateJson]],
            active: [true, []]
        });

        this.nameValidationInterpolationParams = {
            field: this.translateService.translate('common_name').toLowerCase(),
            maximum: '40',
            characters: NameValidationPattern.description
        };

        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '120'
        };

        this.appTemplateService.getAppTemplates({pageSize: 100000}).subscribe(
            (data) => {
                if (data.appTemplates && data.appTemplates.length) {
                    this.appTemplates = sortBy(data.appTemplates, 'title');
                }
            }
        );
    }

    onSubmit(form: FormGroup) {
        this.formService.createFormTemplate({
            appTemplateId: form.value.appTemplate.id,
            name: form.value.name,
            title: form.value.title,
            data: form.value.data,
            active: form.value.active
        }).subscribe(
            res => {
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('forms_create_form_success')
                });
                this.closePopup().then( () => {
                    this.notifyService.notify('formTemplateNew');
                });
            },
            error => {
                this.notificationsService.showError(error);
            }
        );
    }

    closePopup() {
        return this.router.navigate(
            [{outlets: {popup: null}}],
            {queryParamsHandling: 'preserve'}
        );
    }
}

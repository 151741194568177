import { forkJoin } from 'rxjs';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsCommonService } from '../../shared/common.service';
import { CmsPagesService } from '../shared/pages.service';
import { cloneDeep, find } from 'lodash';
import { CmsTranslateService } from '../../shared/translate.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsNotificationsService } from '../../core/notifications.service';
var CmsTranslatePageTemplateComponent = /** @class */ (function () {
    function CmsTranslatePageTemplateComponent(fb, activeRoute, router, commonService, pageService, translateService, notifyService, notificationsService) {
        this.fb = fb;
        this.activeRoute = activeRoute;
        this.router = router;
        this.commonService = commonService;
        this.pageService = pageService;
        this.translateService = translateService;
        this.notifyService = notifyService;
        this.notificationsService = notificationsService;
        this.activeField = {};
        this.baseTranslationDisplay = {};
        this.titleValidationInterpolationParams = {
            field: this.translateService.translate('common_title').toLowerCase(),
            maximum: '80'
        };
        this.subtitleValidationInterpolationParams = {
            field: this.translateService.translate('common_subtitle').toLowerCase(),
            maximum: '120'
        };
        this.keywordsValidationInterpolationParams = {
            field: this.translateService.translate('common_keywords').toLowerCase(),
            maximum: '120'
        };
    }
    CmsTranslatePageTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            title: ['', [Validators.required, Validators.maxLength(80)]],
            subtitle: ['', [Validators.maxLength(120)]],
            keywords: ['', [Validators.maxLength(120)]]
        });
        this.activeRoute.params.subscribe(function (params) {
            forkJoin([
                _this.commonService.getLocales(),
                _this.pageService.getPageTemplate(params.pageId)
            ]).subscribe(function (_a) {
                var localeData = _a[0], basePage = _a[1];
                _this.basePage = basePage.pageTemplate;
                _this.localeName = find(localeData.locales, { 'id': Number(params.toLocaleId) }).title;
                _this.pageService.getPageTemplate(params.pageId, { locale: params.toLocaleId })
                    .subscribe(function (res) {
                    _this.form.patchValue(res.pageTemplate);
                });
            });
        });
    };
    CmsTranslatePageTemplateComponent.prototype.onSubmit = function (form) {
        var _this = this;
        var updatedPageTemplate = cloneDeep(form.value);
        updatedPageTemplate.id = this.activeRoute.snapshot.params.pageId;
        updatedPageTemplate.locale = this.activeRoute.snapshot.params.toLocaleId;
        this.pageService.updatePageTemplateTranslation(updatedPageTemplate)
            .subscribe(function (response) {
            _this.notificationsService.show({
                type: 'action',
                content: _this.translateService.translate('pages_page_template_translated')
            });
            _this.closePopup();
        }, function (error) {
            _this.notificationsService.showError(error);
        });
    };
    CmsTranslatePageTemplateComponent.prototype.toggleBaseTranslation = function (item) {
        this.baseTranslationDisplay[item] = !this.baseTranslationDisplay[item];
    };
    CmsTranslatePageTemplateComponent.prototype.closeBaseTranslation = function (item) {
        this.baseTranslationDisplay[item] = false;
    };
    CmsTranslatePageTemplateComponent.prototype.activateField = function (item) {
        this.activeField = {};
        this.activeField[item] = true;
    };
    CmsTranslatePageTemplateComponent.prototype.closePopup = function () {
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'merge' });
    };
    return CmsTranslatePageTemplateComponent;
}());
export { CmsTranslatePageTemplateComponent };

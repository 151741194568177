import { Component } from '@angular/core';

import { CmsModalService } from '../../core/modal.service';
import { CmsResources } from '../shared/resources.service';
import { CmsNotificationsService } from '../../core/notifications.service';
import { CmsNotifyService } from '../../core/notify.service';
import { CmsTranslateService } from '../../shared/translate.service';

@Component({
    selector: 'cms-delete-resource',
    templateUrl: 'delete-resource.component.html'
})
export class CmsDeleteResourceComponent {
    data: any;

    constructor(
        private modalService: CmsModalService,
        private resourcesService: CmsResources,
        private notificationsService: CmsNotificationsService,
        private notifyService: CmsNotifyService,
        private translateService: CmsTranslateService
    ) {}

    deleteFile() {
        this.closePopup();
        this.notifyService.notify({type: 'resources.delete', id: this.data.resource.id});
        this.resourcesService.deleteResource(this.data.resource).subscribe(
            () => {
                this.notifyService.notify({type: 'resources.closeDeleteModal'});
                this.notificationsService.show({
                    type: 'action',
                    content: this.translateService.translate('resources_file_delete_success')
                });
            },
            (err: any) => {
                this.notificationsService.showError(err);
            },
            () => {
                this.notifyService.notify({type: 'resources'});
            }
        );
    }

    closePopup() {
        this.modalService.close('delete-resource');
    }
}
